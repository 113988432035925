import './App.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { TextField, Grid, Typography, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [times, setTimes] = useState({});
  const [requests, setRequests] = useState(5);
  const [loop, setLoop] = useState(100);
  const [images, setImages] = useState([]);
  const [numImages, setNumImages] = useState(parseInt(searchParams.get('images')) ? parseInt(searchParams.get('images')) : 0);
  const [numCols, setNumCols] = useState(parseInt(searchParams.get('cols')) ? parseInt(searchParams.get('cols')) : 5);
  const [numRows, setNumRows] = useState(parseInt(searchParams.get('rows')) ? parseInt(searchParams.get('rows')) : 10);
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [delay, setDelay] = useState(parseInt(searchParams.get('delay')) ? parseInt(searchParams.get('delay')) : 0)
  const [loading, setLoading] = useState(false);

  let kitchenSinkUrl = `http://localhost:8080`;
  if (process.env.NODE_ENV === 'production') {
    kitchenSinkUrl = `https://api.kitchensink.qualityperformance-test.nikecloud.com`;
  }

  useEffect(() => {
    for (let i = 0; i < numImages; i++)
      images.push(1);
    setImages([...images]);

    if (delay > 0) {
      fetchDelay();
    } else {
      setLoading(false);
    }

    tableRequest();
  }, [])

  const fetchDelay = () => {
    setLoading(true);
    fetch(`${kitchenSinkUrl}/delay?max=${delay}`)
      .then(res => res.json())
      .then(
        (result) => {
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      )
  }

  const loadRequest = () => {
    for (let i = 0; i < requests; i++) {
      times[i] = Date.now();
      fetch(`${kitchenSinkUrl}/load?loop=${loop}`)
        .then(res => res.json())
        .then(
          (result) => {
            times[i] = Date.now() - times[i];
            setTimes({ ...times });
          },
          (error) => {

          }
        )
    }
  }

  const imageRequest = () => {
    setImages([]);
    for (let i = 0; i < numImages; i++)
      images.push(1);
    setImages([...images]);
  }

  const clearImges = () => {
    setImages([]);
  }

  const genCols = num => {
    setNumCols(num);
    const generatedCols = [];
    for (let i = 0; i < numCols; i++) {
      generatedCols.push(<th key={i}>{'Col ' + i}</th>)
    }

    setCols(generatedCols);
    genRows(numRows);
  }

  const genRows = num => {
    setNumRows(num);
    const generatedRows = [];
    for (let i = 0; i < numRows; i++) {
      const generatedItems = []
      for (let x = 0; x < numCols; x++) {
        generatedItems.push(<td key={i + x}>{'Item ' + i + '' + x}</td>)
      }
      generatedRows.push(<tr key={i}>{generatedItems}</tr>)
    }

    setRows(generatedRows);
  }

  const tableRequest = () => {
    genCols(numCols);
  }

  const clearTable = () => {
    setCols([]);
    setRows([]);
  }

  return (
    <div>
      {loading &&
        <div className='loading-indecator'>
          <Oval />
        </div>
      }
      {!loading && <>
        <Grid container direction="column" justifyContent="space-between" alignItems="center" spacing={3}>
          <Grid item>
            <Typography variant="h3">Performance Lighthouse GUI</Typography>
          </Grid>

          {/* Load Request */}
          <Grid item>
            <Grid container direction="column" justifyContent="space-between" alignItems="center" spacing={3}>
              <Grid item>
                <Typography variant="h4">Load Request</Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
                  <Grid item>
                    <TextField type='number' label='Number of Requests' className='surroundMargin' value={requests} onChange={(e) => setRequests(e.target.value)} />
                  </Grid>
                  <Grid item>
                    <TextField type='number' label='Number of Loops' className='surroundMargin' value={loop} onChange={(e) => setLoop(e.target.value)} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={loadRequest}>Generate Requests</Button>
              </Grid>
              <Grid item>
                <Grid item className='scroll-container'>
                  <Grid container direction="column" justifyContent="space-between" alignItems="center">
                    {
                      Object.entries(times).map((time, i) => {
                        return <Grid item key={i}>Request {i}: {times[i]} ms</Grid>
                      })
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Image Request */}
          <Grid item>
            <Grid container direction="column" justifyContent="space-between" alignItems="center" spacing={3}>
              <Grid item>
                <Typography variant="h4">Image Request</Typography>
              </Grid>
              <Grid item>
                <TextField type='number' label='Image Load' value={numImages} onChange={(e) => setNumImages(e.target.value)} />
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
                  <Grid item>
                    <Button variant="contained" onClick={imageRequest}>Generate Images</Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={clearImges}>Clear Images</Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item className='scroll-container'>
                  {
                    images.map((image, i) => {
                      return <img key={i} src='perf-icon.png' />
                    })
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Table Request */}
          <Grid item>
            <Grid container direction="column" justifyContent="space-between" alignItems="center" spacing={3}>
              <Grid item>
                <Typography variant="h4">Table Request</Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
                  <Grid item>
                    <TextField type='number' label='Number of Cols' value={numCols} onChange={(e) => setNumCols(e.target.value)} />
                  </Grid>
                  <Grid item>
                    <TextField type='number' label='Number of Rows' value={numRows} onChange={(e) => setNumRows(e.target.value)} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
                  <Grid item>
                    <Button variant="contained" onClick={tableRequest}>Generate Table</Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={clearTable}>Clear Table</Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item className='scroll-container'>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {cols.map((col, i) => (
                            <TableCell align="right">col {i}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, i) => (
                          <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            {cols.map((col) => (
                              <TableCell align="right">row {i}</TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
      }
    </div>
  );
}

export default App;
